import React from "react"
import { PageWrapper } from "~components/Core"
import FooterOne from "~sections/marketing/FooterOne"
import HeroAboutUs from '~sections/marketing/Herocopy/HeroAboutUs'

import ProcessSection from '~sections/startup/Process'
import FaqSection from "~sections/pricing-2/Faq";


const header = {
  headerClasses: "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid: true
  // customLogo: Images.HeaderLogo,

}

export default function Marketing() {
  return (
    <PageWrapper headerConfig={header}>
      <div className="mt-nav">
        <HeroAboutUs />
        <ProcessSection/>
        <FaqSection/>
        <FooterOne />

      </div>
    </PageWrapper>
  )
}
