import React from 'react'
import {StaticImage as Img} from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Hero from './style'
import { Form, Link } from '~components'
import { Images } from '~data'
export default function HeroAboutUs(){
return(
<Hero overlay={Images.Services.heroImgOverlay} style={{backgroundImage: 'url(https://res.cloudinary.com/dv10simqm/image/upload/v1642106380/helios/Acerca%20de/bg_about_1_vvjaoa.png)'}} >
  <Container>
    <Row className="align-items-center justify-content-center justify-content-md-start">
      {/* Welcome content Area */}
      <Col className="col-xxl-7 col-xl-7 col-lg-9 col-md-10 custom-card">
        <Hero.Content>
          <Hero.Subtitle as="h6" fontColor="#fff">CONÓCENOS</Hero.Subtitle>
          <Hero.Title as="h3" fontColor="#fff" className="">Nuestra misión es crear un mejor futuro para la humanidad.</Hero.Title>
          <Hero.Text as="h6" fontColor="#fff">Nuestra visión. <br /> Cumplir con las expectativas de cada cliente y ser siempre un proveedor de confianza.</Hero.Text>
        </Hero.Content>
        
      </Col>
      {/*/ .Welcome Content Area */}
    </Row>
  </Container>
</Hero>

)
}