const FaqSectionData = [
    {
        "icon":"fas fa-check",
        "title":"Servicio al público",
        "text":"Al proporcionar bienes y servicios de alta calidad a precios razonables, contribuimos al bienestar del público."
    },
    {
        "icon":"fas fa-check",
        "title":"Equidad y honestidad",
        "text":"Seremos justos y honestos en todos nuestros tratos comerciales y conducta personal."
    },
    {
        "icon":"fas fa-check",
        "title":"Trabajo en equipo por la causa común",
        "text":"Pondremos en común nuestras habilidades, basándonos en la confianza y el respeto mutuos."
    },
    {
        "icon":"fas fa-check",
        "title":"Uniendo esfuerzos para la mejora",
        "text":"Nos esforzaremos constantemente por mejorar nuestro desempeño corporativo y personal."
    },
    {
        "icon":"fas fa-check",
        "title":"Cortesía y humildad",
        "text":"Siempre seremos cordiales y modestos y respetaremos los derechos y necesidades de los demás."
    },
    {
        "icon":"fas fa-check",
        "title":"De acuerdo con las leyes naturales",
        "text":"Al proporcionar bienes y servicios de alta calidad a precios razonables, contribuimos al bienestar del público."
    },
    {
        "icon":"fas fa-check",
        "title":"Gratitud por la bendición",
        "text":"Siempre estaremos agradecidos por todas las bendiciones y bondades que hemos recibido."
    }
]


export default FaqSectionData;